exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-digital-wip-005-js": () => import("./../../../src/pages/digital/wip-005.js" /* webpackChunkName: "component---src-pages-digital-wip-005-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mmmmmmmmmmmmmm-js": () => import("./../../../src/pages/mmmmmmmmmmmmmm.js" /* webpackChunkName: "component---src-pages-mmmmmmmmmmmmmm-js" */),
  "component---src-templates-physical-js": () => import("./../../../src/templates/physical.js" /* webpackChunkName: "component---src-templates-physical-js" */)
}

